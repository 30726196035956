import moment from 'moment';

const dateOrDateTimeFormat = (dueAt: string, visibleTolerance: number, format: string): string => {
  // A bit gross, but it's a reliable way to know if it's a date or a datetime string.
  // You could also check if the due string contains a T.
  const isUsingDateTimeDue = dueAt.length > 10;
  const isDueInDifferentYear = moment().format('YYYY') !== moment(dueAt)
    .add(visibleTolerance, format as moment.DurationInputArg2)
    .format('YYYY');

  let displayFormat: string;

  // Ideally format with the user's chosen date format?
  if (isDueInDifferentYear) {
    displayFormat = isUsingDateTimeDue ? 'DD MMM YY HH:mm[z]' : 'DD MMM YY';
  } else {
    displayFormat = isUsingDateTimeDue ? 'DD MMM HH:mm[z]' : 'DD MMM';
  }

  return displayFormat;
}

export default dateOrDateTimeFormat;