import { useQuery } from '@tanstack/react-query';
import { getDefectUseQuery } from '../../api';
import { Defect } from '../../../models/defects';

const useQueryGetDefectForDrawer = ({
  id,
  refetchOnWindowFocus = false,
}: {
  id: string;
  refetchOnWindowFocus?: boolean;
}): { data: Defect; isFetching: boolean } => {
  const { data, isFetching } = useQuery({
    queryKey: ['defectDrawer', id],
    queryFn: () => getDefectUseQuery(id),
    refetchOnWindowFocus,
    enabled: !!id,
  });

  return { data, isFetching };
};

export default useQueryGetDefectForDrawer;
