import { Icon } from 'antd';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getAircraftTripEntries } from '../../models/trips/actions';
import rightArrow from '../../assets/trip-arrow.svg';
import { changeDrawerContent, changeDrawerVisibility, setDrawerId } from '../../models/drawer';
import FlightDrawer from '../../components/FlightDrawer/FlightDrawer';
import DocumentLink from '../../components/DocumentLink/DocumentLink';
import styles from './OverviewRecentTrips.module.less';

class OverviewRecentTrips extends Component {
  static propTypes = {
    aircraft: PropTypes.object,
    intl: PropTypes.object.isRequired,
    tripsArr: PropTypes.array.isRequired,
    dispatch: PropTypes.func.isRequired,
    userSettings: PropTypes.object.isRequired,
  };

  static defaultProps = {
    aircraft: {},
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { aircraft, dispatch } = this.props;
    if (aircraft?.id) {
      dispatch(
        getAircraftTripEntries({
          payload: {
            id: aircraft.id,
            limit: 3,
          },
        }),
      );
    }
  }

  componentDidUpdate(prevProps) {
    const { aircraft, dispatch } = this.props;
    if (aircraft?.id && aircraft?.id !== prevProps.aircraft?.id) {
      dispatch(
        getAircraftTripEntries({
          payload: {
            id: aircraft.id,
            limit: 3,
          },
        }),
      );
    }
  }

  getSectorStatus = (status) => {
    switch (status) {
      case 'partial':
        return {
          badgeStyle: styles.statusBadgeBlue,
          boxStyle: styles.statusMessageBlue,
          messageId: 'status.partial',
        };
      case 'in_progress':
        return {
          badgeStyle: styles.statusBadgeBlue,
          boxStyle: styles.statusMessageBlue,
          messageId: 'status.inProgress',
        };
      case 'planned':
        return {
          badgeStyle: styles.statusBadgeLightGrey,
          boxStyle: styles.statusMessageLightGrey,
          messageId: 'status.planned',
        };
      case 'error':
        return {
          badgeStyle: styles.statusBadgeRed,
          boxStyle: styles.statusMessageRed,
          messageId: 'status.error',
        };
      default:
        return {
          badgeStyle: styles.statusBadgeGreen,
          boxStyle: styles.statusMessageHidden,
          messageId: 'status.complete',
        };
    }
  };

  sectorRow = (flight, index, sectorNameOverride, trip) => {
    const {
      intl: { formatMessage },
      dispatch,
    } = this.props;

    const statusFormat = this.getSectorStatus(flight.status);

    const postFlightMissing = trip?.status === 'complete' && flight.status === 'in_progress';

    return (
      <div key={flight.id} className={styles.rowWrapper} data-testid={`OverviewRecentTrips--sector${flight.id}`}>
        <button
          type="button"
          className={styles.linkButton}
          onClick={() => {
            dispatch(changeDrawerVisibility({ payload: true }));
            dispatch(changeDrawerContent({ payload: { content: <FlightDrawer /> } }));
            dispatch(setDrawerId({ payload: flight.id }));
          }}
        >
          <div className={styles.statusWrapper} data-testid={`OverviewRecentTrips--sectorStatus${flight.id}`}>
            <div className={statusFormat.badgeStyle} />
            <div className={styles.sectorNumber}>
              {sectorNameOverride} {index + 1}
            </div>
            <div className={statusFormat.boxStyle}>
              <div className={styles.statusMessageText}>{formatMessage({ id: statusFormat.messageId })}</div>
            </div>
            {postFlightMissing && (
              <div className={styles.statusMessageRed}>
                <div className={styles.statusMessageText}>{formatMessage({ id: 'status.postflightMissing' })}</div>
              </div>
            )}
          </div>
          <div className={styles.depArrWrapper} data-testid={`OverviewRecentTrips--sectorDepArr${flight.id}`}>
            <div className={styles.airportLabel}>{flight.departure_airport}</div>
            {flight.time_offblocks ? (
              <div className={styles.timeLabel}>{`(${moment(flight.time_takeoff).utc().format('HH:mm')})`}</div>
            ) : (
              ''
            )}
            <div className={styles.depArrDivider}>
              <img src={rightArrow} alt="rarrow" />
            </div>
            <div className={styles.airportLabel}>{flight.arrival_airport}</div>
            {flight.time_onblocks ? (
              <div className={styles.timeLabel}>{`(${moment(flight.time_landing).utc().format('HH:mm')})`}</div>
            ) : (
              ''
            )}
            <div className={styles.openFlightChevron}>
              <Icon type="right" />
            </div>
          </div>
        </button>
      </div>
    );
  };

  lastThreeTrips = (tripsArr) => {
    const {
      intl: { formatMessage },
      userSettings: {
        dateFormat,
        details: { operators },
      },
      aircraft,
      aircraft: { standard_fields },
    } = this.props;
    const hideJourneyLog = standard_fields?.hide_journey_log?.enabled;

    const operatorSettings = operators.find((op) => aircraft?.operator_id === op.id)?.operator_setting;
    const sectorNameOverride = operatorSettings?.sector_name || formatMessage({ id: 'title.sector' });
    const trips = tripsArr.slice(0, 3).map((trip) => (
      <div key={trip.id}>
        <div key={trip.number} className={styles.tripCard} data-testid="OverviewRecentTrips--tripCard">
          <div className={trip.flights ? styles.tripHeaderWrapper : styles.tripHeaderWrapperNoSectors}>
            <div className={styles.numberWrapper} data-testid={`OverviewRecentTrips--tripNumber${trip.number}`}>
              <div className={styles.tripNumber}>{`#${trip && trip.number}`}</div>
              <div className={styles.tripDate}>{trip && moment(trip.date).format(dateFormat)}</div>
            </div>
            <div className={styles.nameWrapper} data-testid={`OverviewRecentTrips--tripPilot${trip.number}`}>
              <div className={styles.peopleNames}>
                <div>
                  {trip?.captain?.first_name} {trip?.captain?.last_name}
                  {trip?.first_officer && ','}{' '}
                </div>
                <div className={styles.firstOfficerDiv}>
                  {trip?.first_officer?.first_name} {trip?.first_officer?.last_name}
                </div>
              </div>
              <div className={`${styles.pdfWrapper} ${styles.SRPPdf}`}>
                <DocumentLink docType="srp" id={trip.id} number={trip.number} aircraftId={aircraft.id} />
              </div>
              {trip && trip.type === 'Trip' && !hideJourneyLog ? (
                <div className={`${styles.pdfWrapper} ${styles.SRPPdf}`}>
                  <DocumentLink docType="jl" id={trip.id} aircraftId={aircraft.id} />
                </div>
              ) : null}
            </div>
          </div>
          <div className={styles.tripListWrapper}>
            {trip?.type === 'Trip'
              ? trip?.flights.map((flight, sectorIndex) =>
                  this.sectorRow(flight, sectorIndex, sectorNameOverride, trip),
                )
              : null}
          </div>
        </div>
        <div
          key={`${trip.number} mobile`}
          className={styles.tripCardMobile}
          data-testid="OverviewRecentTrips--tripCardMobile"
        >
          <div className={trip.flights ? styles.tripHeaderWrapper : styles.tripHeaderWrapperNoSectors}>
            <div className={styles.mobileTripHeader}>
              <div className={styles.numberWrapper} data-testid={`OverviewRecentTrips--tripNumberMobile${trip.number}`}>
                <div className={styles.tripNumber}>{`#${trip && trip.number}`}</div>
                <div className={styles.tripDate}>{trip && trip.date}</div>
              </div>
              <div className={styles.nameWrapper} data-testid={`OverviewRecentTrips--tripPilotMobile${trip.number}`}>
                <div className={styles.peopleNames}>
                  <div>
                    {trip?.captain?.first_name} {trip?.captain?.last_name}
                    {trip?.first_officer && ','}{' '}
                  </div>
                  <div className={styles.firstOfficerDiv}>
                    {trip?.first_officer?.first_name} {trip?.first_officer?.last_name}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.mobileSRPLayout}>
              <div className={`${styles.pdfWrapper} ${styles.SRPPdf}`}>
                <DocumentLink docType="srp" id={trip.id} number={trip.number} aircraftId={aircraft.id} />
              </div>
              {trip && trip.type === 'Trip' ? (
                <div className={`${styles.pdfWrapper} ${styles.SRPPdf}`}>
                  <DocumentLink docType="jl" id={trip.id} aircraftId={aircraft.id} />
                </div>
              ) : null}
            </div>
          </div>
          <div className={styles.tripListWrapper}>
            {trip?.type === 'Trip'
              ? trip?.flights.map((flight, sectorIndex) =>
                  this.sectorRow(flight, sectorIndex, sectorNameOverride, trip),
                )
              : null}
          </div>
        </div>
      </div>
    ));
    return trips;
  };

  render() {
    const { tripsArr } = this.props;
    return (
      <div className={styles.tripContainer} data-testid="OverviewRecentTrips--tripsTable">
        {this.lastThreeTrips(tripsArr)}
      </div>
    );
  }
}

const overviewRecentTripsWithRedux = connect(({ trips, userSettings }, { aircraft }) => {
  const tripsArr = Array.from(trips.tripsMap.values())
    .filter((tripEntry) => aircraft && tripEntry.aircraft_id === aircraft.id)
    .sort((a, b) => moment(b.date).diff(moment(a.date)));
  let tripUpdate = {};
  if (trips.newTrip) {
    tripUpdate = trips.newTrip;
  }
  return {
    tripUpdate,
    tripsArr,
    userSettings,
  };
})(OverviewRecentTrips);

export default withRouter(injectIntl(overviewRecentTripsWithRedux));
