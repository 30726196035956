/* eslint-disable @typescript-eslint/no-explicit-any */
import { intl } from '../../providers/IntlProvider';
import { ToastCategories, ToastTypes, addToast } from '../toasts';
import { getCapabilites, getUserDetails, setUserDetails, updateFleetStyle, updateOperator } from '../../services/api';
import { UserSettingsActionTypes } from '.';

export const fetch =
  () =>
  async (dispatch: (arg: any) => any): Promise<any> => {
    const response = await getUserDetails();
    if (response && response.people) {
      dispatch({
        type: UserSettingsActionTypes.SAVE,
        payload: { ...response, people: response.people },
      });
    }
  };

export const updateFleetPageStyle = ({ fleetStyle }: { fleetStyle: string }): any => {
  return {
    type: UserSettingsActionTypes.SET_FLEET_STYLE,
    fleetStyle,
  };
};

export const add =
  ({ payload }: { payload: any }) =>
  async (dispatch: (arg: any) => any): Promise<any> => {
    let response = null;
    if (payload.type === 'fleetStyle') {
      response = await updateFleetStyle(payload.form);
      dispatch(updateFleetPageStyle({ fleetStyle: payload.form.fleet_style }));
    } else {
      response = await setUserDetails(payload);
    }

    const { formatMessage } = intl;

    const sendToast = ({ title, type }): void => {
      dispatch(
        addToast({
          payload: {
            title,
            type,
            category: type === ToastTypes.SUCCESS ? ToastCategories.FLASH : ToastCategories.FLAG,
            message: '',
          },
        }),
      );
    };
    if (response) {
      let title = '';
      if (payload.avatar) {
        title = formatMessage({ id: 'text.accountSettings.uploadProfileSuccessful' });
      } else if (payload.type === 'totals') {
        title = formatMessage({ id: 'text.aircraftTotalsVisibilitySavedSuccessfully' });
      } else if (payload.type === 'usersnap') {
        // do nothing
      } else if (payload.type === 'date') {
        title = formatMessage({ id: 'text.dateFormatUpdated' });
      } else if (payload.type === 'pin') {
        title = formatMessage({ id: 'text.pinUpdatedSuccessfully' });
        return 'pin updated';
      } else if (payload.form) {
        title = formatMessage({ id: 'text.logbookColumnsSaved' });
      } else {
        title = formatMessage({ id: 'text.accountSettings.settingsSaved' });
      }
      sendToast({
        title,
        type: ToastTypes.SUCCESS,
      });
      dispatch({
        type: UserSettingsActionTypes.SAVE,
        payload: response,
        avatar: new Date(),
      });
    } else {
      sendToast({
        title: formatMessage({ id: 'text.updateFaile' }),
        type: ToastTypes.ERROR,
      });
    }
    return null;
  };

export const updateOperatorRedux =
  ({ payload }: { payload: any }) =>
  async (dispatch: (arg: any) => any): Promise<void> => {
    const fuelBurnEnabled =
      payload.operator.operator_setting.fuel_burn_on_mx_enabled === true ||
      payload.operator.operator_setting.fuel_burn_on_trips_enabled === true;
    // Ensure that if either of the seperate fuel burn settings are on we also turn on the depreciated
    // fuel burn required setting. We need to do this because old Ipads will still use the old fuel burn setting
    const payloadWithFuelBurnEnabled = {
      ...payload,
      operator: {
        ...payload.operator,
        operator_setting: {
          ...payload.operator.operator_setting,
          fuel_burn_required: fuelBurnEnabled,
        },
        operator_setting_attributes: {
          ...payload.operator.operator_setting_attributes,
          fuel_burn_required: fuelBurnEnabled,
        },
      },
    };
    const response = await updateOperator(payloadWithFuelBurnEnabled);
    dispatch(
      addToast({
        payload: {
          title: 'Operator updated successfully',
          type: ToastTypes.SUCCESS,
          category: ToastCategories.FLASH,
          message: '',
        },
      }),
    );
    dispatch({
      type: UserSettingsActionTypes.UPDATE_OPERATOR_SETTINGS,
      payload: response,
    });
  };

export const selectView =
  ({ payload }: { payload: any }) =>
  (dispatch: (arg: any) => any): void => {
    dispatch({
      type: UserSettingsActionTypes.SET_SELECTED_VIEW,
      payload,
    });
  };

export const updateUserDetails = ({ details }: { details: any }): any => {
  return {
    type: UserSettingsActionTypes.UPDATE_DETAILS,
    details,
  };
};

export const addFavouriteAircraftId = ({ aircraftId }: { aircraftId: string }): any => {
  return {
    type: UserSettingsActionTypes.ADD_FAVOURITE_AIRCRAFT_ID,
    aircraftId,
  };
};

export const removeFavouriteAircraftId = ({ aircraftId }: { aircraftId: string }): any => {
  return {
    type: UserSettingsActionTypes.REMOVE_FAVOURITE_AIRCRAFT_ID,
    aircraftId,
  };
};

export const setUserCapabilities = () => async (dispatch: (arg: any) => any): Promise<void> => {
  try {
    const response = await getCapabilites();
    if (response) {
      dispatch({
        type: UserSettingsActionTypes.SET_CAPABILITIES,
        payload: response,
      });
    }
  } catch (error) {
    dispatch(addToast({
      payload: {
        title: 'Error fetching capabilities',
        type: ToastTypes.ERROR,
        category: ToastCategories.FLASH,
        message: error.message,
      },
    }));
  }
};
