/* eslint-disable react/prop-types */
import { useMemo } from 'react';

const createFilterModel = (totals, types, hideAircraft, resolutionRename) => {
  const aircraftTotals = totals?.aircraft_totals ? Object.entries(totals.aircraft_totals) : [];
  const aircraftFilters = {
    groupLabel: 'Aircraft',
    filters: aircraftTotals.map(([reg, count]) => {
      return {
        label: reg,
        key: 'aircraft',
        value: reg,
        count,
        filterFunction: () => {}, // This is unused but required, the filtering is done on the server.
      };
    }),
  };
  const filters = [
    {
      groupLabel: 'Status',
      filters: [
        {
          label: 'Deferral Pending',
          key: 'status',
          value: 'deferral_pending',
          badge: 'red',
          count: totals ? totals.status.deferral_pending : null,
          filterFunction: () => {}, // This is unused but required, the filtering is done on the server.
        },
        {
          label: 'Overdue',
          key: 'status',
          value: 'overdue',
          badge: 'red',
          count: totals ? totals.status.overdue : null,
          filterFunction: () => {}, // This is unused but required, the filtering is done on the server.
        },
        {
          label: 'Open',
          key: 'status',
          value: 'open',
          badge: 'amber',
          count: totals ? totals.status.open : null,
          filterFunction: () => {}, // This is unused but required, the filtering is done on the server.
        },
        {
          label: 'Resolved',
          key: 'status',
          value: 'resolved',
          badge: 'green',
          count: totals ? totals.status.resolved : null,
          filterFunction: () => {}, // This is unused but required, the filtering is done on the server.
        },
        {
          label: 'Draft',
          key: 'status',
          value: 'draft',
          badge: 'grey',
          count: totals ? totals.status.draft : null,
          filterFunction: () => {}, // This is unused but required, the filtering is done on the server.
        },
      ],
    },
    {
      groupLabel: 'Type',
      filters: [
        {
          label: types?.cas,
          key: 'type',
          value: 'CAS',
          count: totals ? totals.type.cas : null,
          filterFunction: () => {}, // This is unused but required, the filtering is done on the server.
        },
        {
          label: types?.cdl,
          key: 'type',
          value: 'CDL',
          count: totals ? totals.type.cdl : null,
          filterFunction: () => {}, // This is unused but required, the filtering is done on the server.
        },
        {
          label: types?.mel,
          key: 'type',
          value: 'MEL',
          count: totals ? totals.type.mel : null,
          filterFunction: () => {}, // This is unused but required, the filtering is done on the server.
        },
        {
          label: types?.nef,
          key: 'type',
          value: 'NEF',
          count: totals ? totals.type.nef : null,
          filterFunction: () => {}, // This is unused but required, the filtering is done on the server.
        },
        {
          label: types?.other,
          key: 'type',
          value: 'Other',
          count: totals ? totals.type.other : null,
          filterFunction: () => {}, // This is unused but required, the filtering is done on the server.
        },
      ],
    },
    {
      groupLabel: 'Deferred',
      filters: [
        {
          label: 'Deferred',
          key: 'deferred',
          value: true,
          count: totals ? totals.deferred.deferred : null,
          filterFunction: () => {}, // This is unused but required, the filtering is done on the server.
        },
        {
          label: 'Non-deferred',
          key: 'deferred',
          value: false,
          count: totals ? totals.deferred.non_deferred : null,
          filterFunction: () => {}, // This is unused but required, the filtering is done on the server.
        },
      ],
    },
  ];
  if (!hideAircraft) {
    filters.push(aircraftFilters);
  }
  if (totals?.status && Object.keys(totals.status).includes('resolution_pending')) {
    const resolutionPendingFilter =
    {
      label: `${resolutionRename || 'Resolution'} Pending`,
      key: 'status',
      value: 'resolution_pending',
      badge: 'red',
      count: totals ? totals.status.resolution_pending : null,
      filterFunction: () => {},
    };
    filters[0].filters.splice(1, 0, resolutionPendingFilter);
  }
  return filters;
};

export const useDefectsFilterModel = (totals, types, hideAircraft, resolutionRename) => {
  return useMemo(
    () => createFilterModel(totals, types, hideAircraft, resolutionRename),
    [totals, types, hideAircraft, resolutionRename]
  );
};

export default createFilterModel;
