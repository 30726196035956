/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Icon } from 'antd';
import { formatTime } from '../../../utils/utils';
import expandIcon from '../../../assets/expand.svg';
import completeIcon from '../../../assets/icon-complete.svg';
import bin from '../../../assets/delete-red.svg';
import collapseIcon from '../../../assets/collapse.svg';
import styles from '../workpackDrawer.module.less';
import dateOrDateTimeFormat from '../../../utils/displayUtils';

const sourceDictionary = {
  core: { text: 'TrustFlight', logo: <Icon type="plus" className={styles.iconBlue} /> },
  camp: { text: 'CAMP', logo: <Icon type="arrow-down" className={styles.iconAmber} /> },
};

const calculateColour = (threshold, value): string => {
  if (value <= (threshold * 4) / 3 && value > 0) return '#f2a650';
  if (value <= 0) return '#ff4040';
  return '#35b96d';
};

const prepareUnits = (item): any => {
  const hoursThreshold = item.flight_seconds_threshold / 3600 || 30;
  const daysThreshold = item.unit_of_time === 'days' ? item.days_threshold : item.months_threshold || 30;
  const apuHoursThreshold = item.apu_seconds_threshold / 3600 || 30;
  const cyclesThreshold = item.cycles_threshold || 30;
  const statusDetails = {
    label: 'Status',
    hours: Math.floor(item.flight_seconds_remaining / 3600) || 0,
    hoursColour: calculateColour(hoursThreshold, Math.floor(item.flight_seconds_remaining / 3600)),
    cycles: item.cycles_remaining || 0,
    cyclesColour: calculateColour(cyclesThreshold, item.cycles_remaining),
    months: moment()
      .add(item.days_remaining, 'days')
      .diff(moment(), item.unit_of_time === 'days' ? 'days' : 'months'),
    monthsColour: calculateColour(daysThreshold, item.days_remaining),
    apu: Math.floor(item.apu_seconds_remaining / 3600) || 0,
    apuColour: calculateColour(apuHoursThreshold, Math.floor(item.apu_seconds_remaining / 3600)),
    unitOfTime: item.unit_of_time,
  };

  const hrsTimeDetails = {
    label: 'Flight Hours',
    unit: { sing: 'hr', plur: 'hrs' },
    repeat: item.flight_seconds_repeat,
    due: item.flight_seconds_due,
    remaining: item.flight_seconds_remaining,
    visible_tolerance: item.flight_seconds_visible_tolerance,
  };

  const APUhrsTimeDetails = {
    label: 'APU Hours',
    unit: { sing: 'hr', plur: 'hrs' },
    repeat: item.apu_seconds_repeat,
    due: item.apu_seconds_due,
    remaining: item.apu_seconds_remaining,
    visible_tolerance: item.apu_seconds_visible_tolerance,
  };

  const cyclesTimeDetails = {
    label: 'Cycles',
    unit: { sing: 'cycle', plur: 'cycles' },
    repeat: item.cycles_repeat,
    due: item.cycles_due,
    remaining: item.cycles_remaining,
    visible_tolerance: item.cycles_visible_tolerance,
  };
  const isDays = item.unit_of_time === 'days';
  const daysTimeDetails = {
    label: isDays ? 'Days' : 'Months',
    unit: isDays ? { sing: 'day', plur: 'days' } : { sing: 'month', plur: 'months' },
    repeat: isDays ? item.days_repeat : item.months_repeat,
    due: item.date_due || item.datetime_due,
    remaining: item.days_remaining,
    visible_tolerance: isDays ? item.days_visible_tolerance : item.months_visible_tolerance,
    format: item.unit_of_time
  };

  return { cyclesTimeDetails, daysTimeDetails, hrsTimeDetails, statusDetails, APUhrsTimeDetails };
};

const Hours = ({ item }): JSX.Element => {
  const repeatDisplay = item.repeat ? formatTime(item.repeat) : null;
  const dueDisplay =
    item.visible_tolerance === null ? formatTime(item.due) : formatTime(item.due + item.visible_tolerance);

  const toleranceVisibleDisplay = item.visible_tolerance === null ? null : Math.round(item.visible_tolerance / 3600);

  return (
    <>
      <div className={styles.rowItem}>
        <span className={styles.mxItemLabel}>{item.label}</span>
        {repeatDisplay !== null ? (
          <>
            <span className={styles.innerLabel}>INTERVAL:</span>
            <span>{`${repeatDisplay} hrs` || '-'}</span>
            <br />
          </>
        ) : null}
        {dueDisplay !== null ? (
          <>
            <span className={styles.innerLabel}>NEXT DUE:</span>
            <span>{(dueDisplay !== null && `${dueDisplay} hrs`) || '-'}</span>
            <span>
              {toleranceVisibleDisplay !== (null || 0) && `Using: ${toleranceVisibleDisplay} unit of tolerance`}
            </span>
          </>
        ) : (
          '-'
        )}
      </div>
    </>
  );
};

const Months = ({ item }): JSX.Element => {
  let dueDisplay;

  if (item.due) {
    const displayFormat = dateOrDateTimeFormat(item.due, item.visible_tolerance, item.format);
    dueDisplay = moment(item.due).add(item.visible_tolerance, item.format).format(displayFormat);
  }

  return (
    <>
      <div className={styles.rowItem}>
        <span data-testid="due-label" className={styles.mxItemLabel}>{item.label}</span>
        {item?.repeat ? (
          <>
            <span className={styles.innerLabel}>INTERVAL:</span>
            <span>{`${item?.repeat} ${item.label === 'Days' ? 'days' : 'mos'}`}</span>
            <br />
          </>
        ) : null}
        {dueDisplay ? (
          <>
            <span className={styles.innerLabel}>NEXT DUE:</span>
            <span data-testid="due-display">{dueDisplay}</span>
            <span data-testid="due-tolerance">
              {item.visible_tolerance ? `Using: ${item?.visible_tolerance} unit of tolerance` : null}
            </span>
          </>
        ) : (
          <span data-testid="due-display">-</span>
        )}
      </div>
    </>
  );
};

const Cycles = ({ item }): JSX.Element => {
  const dueDisplay = item.visible_tolerance === null ? item.due : item.due + item.visible_tolerance;
  return (
    <>
      <div className={styles.rowItem}>
        <span className={styles.mxItemLabel}>{item.label}</span>
        {item.repeat !== null ? (
          <>
            <span className={styles.innerLabel}>INTERVAL:</span>
            <span>{`${item.repeat} cycles`}</span>
            <br />
          </>
        ) : null}
        {dueDisplay !== (null || 0) ? (
          <>
            <span className={styles.innerLabel}>NEXT DUE:</span>
            <span>{`${dueDisplay} cycles`}</span>
            <span>
              {item.visible_tolerance !== (null || 0) && `Using: ${item.visible_tolerance} unit of tolerance`}
            </span>
          </>
        ) : (
          '-'
        )}
      </div>
    </>
  );
};

const StatusDetails = ({ item, apuInstalled }): JSX.Element => {
  return (
    <div className={styles.rowItem}>
      <span className={styles.mxItemLabel}>{item.label}</span>
      <span>
        <span style={{ color: item.hoursColour }}>{item.hours > -1 ? item.hours : '0'}</span> hrs left
      </span>
      <span>
        <span style={{ color: item.cyclesColour }}>{item.cycles > -1 ? item.cycles : '0'}</span> cycles left
      </span>
      <div>
        <span style={{ color: item.monthsColour }}>{item.months > -1 ? item.months : '0'}</span> {item.unitOfTime} left
      </div>
      {apuInstalled ? (
        <span>
          <span style={{ color: item.apuColour }}>{item.apu > -1 ? item.apu : '0'}</span> apu hrs left
        </span>
      ) : null}
    </div>
  );
};

const MXDetails = ({ details, mode, apuInstalled }): JSX.Element => {
  const { cyclesTimeDetails, daysTimeDetails, hrsTimeDetails, statusDetails, APUhrsTimeDetails } =
    prepareUnits(details);
  const { status } = details;
  let MXDetailsWrapper = styles.defectResolution;
  if (mode === 'edit') MXDetailsWrapper = styles.defectResolutionEdit;
  if (status === 'resolved') MXDetailsWrapper = styles.defectResolutionResolved;
  return (
    <div className={MXDetailsWrapper}>
      <span className={styles.header}>Maintenance Details</span>
      <div className={apuInstalled ? styles.rowGridFive : styles.rowGridFour}>
        <StatusDetails item={statusDetails} apuInstalled={apuInstalled} />
        <Hours item={hrsTimeDetails} />
        <Months item={daysTimeDetails} />
        <Cycles item={cyclesTimeDetails} />
        {apuInstalled ? <Hours item={APUhrsTimeDetails} /> : null}
      </div>
    </div>
  );
};

const ScheduledMX = ({ item: { mx_item, id, mx_item_id }, mode, removeWPItem, apuInstalled }): JSX.Element => {
  const [showMXDetails, setShowMXDetails] = useState(false);
  const toggleMXDetails = (): void => setShowMXDetails((state) => !state);

  const history: any = useHistory();

  let engineNumber = null;
  const source = sourceDictionary[mx_item.source];

  const mxType = mx_item?.mx_type?.split('_');
  const isThereAnEngineNumber = mxType ? mxType.findIndex((isThisANumber) => !Number.isNaN(Number(isThisANumber))) : -1;

  if (isThereAnEngineNumber > -1) {
    engineNumber = mxType.splice(isThereAnEngineNumber, 1);
  }

  const colourChart = {
    SCH: '#470cfe',
    OOP: '#794efc',
    LLP: '#baa3ff',
    EXTRA: '#004c63',
  };

  const { name } = mx_item;
  const [area, type] = mxType || [];

  const maintenanceArea = area?.replace(area.charAt(0), area.charAt(0).toUpperCase());
  const maintenanceType = type?.substr(0, 3).toUpperCase() || 'EXTRA';

  useEffect(() => {
    const itemId = history?.location?.state?.itemId;
    if (itemId && itemId === mx_item_id) setShowMXDetails(true);
  }, [history, mx_item_id]);

  return (
    <div>
      <div className={styles.wpRowWrapper}>
        {mx_item?.status === 'resolved' ? (
          <div className={styles.checkbox}>
            <img src={completeIcon} alt="complete icon" className={styles.tickIcon} />
          </div>
        ) : null}
        <div
          role="button"
          key={id}
          className={showMXDetails ? styles.wpItemRowExpanded : styles.wpItemRow}
          onClick={toggleMXDetails}
        >
          <div className={styles.itemDesWrapper}>
            <span className={styles.mxType} style={{ backgroundColor: `${colourChart[maintenanceType]}` }}>
              {maintenanceType}{' '}
            </span>
            <span className={showMXDetails ? styles.mxLabelExpanded : styles.mxLabel}>
              {maintenanceArea ? (
                <span>
                  <span className={styles.chapterName}>{maintenanceArea}</span>
                  {engineNumber}, {name}
                </span>
              ) : (
                name
              )}
            </span>
          </div>
          <div className={styles.expandWrapper}>
            <span className={styles.sourceWrapper}>
              <span className={styles.logo}>{source && source.logo}</span>
              <span className={styles.logoText}>{source && source.text}</span>
            </span>
            {mx_item?.status === 'resolved' ? (
              <span className={styles.outcome}>
                {mx_item?.mx_events &&
                mx_item?.mx_events[0] &&
                mx_item?.mx_events[0]?.part_changes &&
                mx_item?.mx_events[0]?.part_changes.length > 0
                  ? 'Part Replaced'
                  : 'Complied With'}
              </span>
            ) : (
              <span className={styles.outcome}>
                Created
                {moment(mx_item.created_at).format('DD MMM YYYY')}
              </span>
            )}
            <img src={showMXDetails ? collapseIcon : expandIcon} alt="expand icon" className={styles.expandIcon} />
          </div>
        </div>
        {(mode === 'edit' || mode === 'new') && (
          <button type="button" onClick={(): void => removeWPItem(id, mx_item_id)} className={styles.bin}>
            <img src={bin} alt="bin" />
          </button>
        )}
      </div>
      {showMXDetails && <MXDetails details={mx_item} mode={mode} apuInstalled={apuInstalled} />}
    </div>
  );
};

export default ScheduledMX;
