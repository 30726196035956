import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { addDefectUseQuery } from "../../api";
import { addToast, ToastCategories, ToastTypes } from "../../../models/toasts";

interface UseMutationAddDefectProps {
  handleAddDefectOnSuccess: ({ data }: { data: any }) => void;
}

const useMutationAddDefect =
({ handleAddDefectOnSuccess }:
  UseMutationAddDefectProps): UseMutationResult<unknown, Error, unknown, unknown> => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: addDefectUseQuery,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['defectDrawer'] });
      handleAddDefectOnSuccess({ data });
      dispatch(
        addToast({
          payload: {
            title: 'Defect added',
            message: 'Defect has been added successfully',
            type: ToastTypes.SUCCESS,
            category: ToastCategories.FLASH,
          },
        }),
      );
    },
    onError: (err: Error) => {
      dispatch(
        addToast({
          payload: {
            title: 'Defect creation error',
            message: err.message,
            type: ToastTypes.ERROR,
            category: ToastCategories.FLAG,
          },
        }),
      );
    },
  });
};

export default useMutationAddDefect;
