import React, { ReactElement, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Portal from '../TFPortal/TFPortal';
import closeIcon from '../../assets/cancel-blue.svg';
import tickIcon from '../../assets/tick-blue.svg';
import bin from '../../assets/icon-action-delete.svg';
import redBin from '../../assets/delete-red.svg';

interface TFSelectDropdownProps {
  options?: any;
  selected?: any;
  handleSelectChange: (option) => void;
  handleRemoveClick: (option) => void;
  handleAddClick?: (option) => void;
  handleDeleteClick?: (option) => void;
  displayTop?: boolean;
  addOption?: boolean;
}

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;

const StyledButton = styled.button`
  min-height: 40px;
  width: 100%;
  border-radius: 2px;
  border: solid 1px rgba(36, 45, 65, 0.05);
  background-color: #f3f7fc;
  padding-left: 8px;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  row-gap: 4px;
`;

const InvisibleBackground = styled.button`
  position: absolute;
  width: 100%;
  height: 100%;
  outline: none;
  padding: 0;
  border: none;
  background-color: transparent;
  z-index: 1001;
`;

const OptionsWrapper = styled.div`
  border: 1px solid rgba(36, 45, 65, 0.1);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  z-index: 606;
  max-height: 200px;
  overflow-y: auto;
`;

const ListOption = styled.button`
  padding: 4px 8px;
  border-radius: 2px;
  background-color: ${({ selected }): string => (selected ? '#126fd6' : 'transparent')};
  color: ${({ selected }): string => (selected ? '#fff' : '#241d41')};
  border: none;
  width: 100%;
  border: 1px solid #fff;
  text-align: left;
  cursor: pointer;
  z-index: 3;
  &:hover {
    color: ${({ selected }): string => (selected ? '#fff' : '#126fd6')};
    font-weight: 500;
    border: 1px solid #126fd6;
  }
`;

const SelectedOption = styled.div`
  width: fit-content;
  border: 1px solid rgba(36, 45, 65, 0.1);
  margin-right: 4px;
  background-color: #fff;
  flex-shrink: 0;
  padding: 4px;
  display: flex;
`;

const CloseButton = styled.button`
  border: none;
  padding: 0;
  padding-left: 8px;
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  img {
    width: 12px;
  }
`;

const StyledInput = styled.input`
  width: 100%;
  border: none;
  &:focus {
    outline: none;
  }
`;

const AddButton = styled.button`
  background: transparent;
  border: none;
  color: #126fd6;
  cursor: pointer;
  width: 100%;
  text-align: left;
  padding: 4px 9px;
  &:focus {
    outline: none;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;

const ConfirmButton = styled.button`
  background: transparent;
  border: none;
  color: #126fd6;
  cursor: pointer;
  flex-shrink: 0;
  img {
    width: 14px;
  }
`;

const InputButtonWrapper = styled.button`
  background: transparent;
  border: none;
  color: #126fd6;
  cursor: pointer;
  flex-shrink: 0;
  display: flex;
  width: 100%;
`;

const OptionWrapper = styled.button`
  background: transparent;
  border: none;
  color: ${({ selected }): string => (selected ? '#fff' : '#241d41')};
  cursor: pointer;
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0;
  &:hover {
    color: ${({ selected }): string => (selected ? '#fff' : '#126fd6')};
    font-weight: 500;
  }
`;

const DeleteButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  flex-shrink: 0;
  padding: 0;
  img {
    width: 14px;
  }
`;

const TFSelectDropdown: React.FC<TFSelectDropdownProps> = ({
  options,
  selected = [],
  handleSelectChange,
  handleRemoveClick,
  displayTop,
  addOption,
  handleAddClick,
  handleDeleteClick,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [localOptions, setLocalOptions] = useState([]);
  const [optionsStyling, setOptionsStyling] = useState(null);
  const [backgroundStyling, setBackgroundStyling] = useState(null);
  const [addOptionSelected, setAddOptionSelected] = useState(false);
  const [newTypedOption, setNewTypedOption] = useState(null);

  const buttonRef = useRef(null);

  const handleInputClick = (): void => {
    setShowOptions(!showOptions);
    const rect = buttonRef?.current?.getBoundingClientRect();
    const { top, left, width, height } = rect;
    if (displayTop) {
      setOptionsStyling({
        position: 'absolute',
        top: top - 208,
        left,
        width,
      });
    } else {
      setOptionsStyling({
        position: 'absolute',
        top: top + height + 8,
        left,
        width,
      });
    }
    setBackgroundStyling({ top: 0 + window.scrollY, left: 0 + window.scrollX });
  };

  const handleOptionClick = (option): void => {
    handleSelectChange(option);
    setTimeout(() => {
      const rect = buttonRef?.current?.getBoundingClientRect();
      const { top, left, width, height } = rect;
      if (displayTop) {
        setOptionsStyling({
          position: 'absolute',
          top: top - 208,
          left,
          width,
        });
      } else {
        setOptionsStyling({
          position: 'absolute',
          top: top + height + 8,
          left,
          width,
        });
      }
    }, 20);
  };

  const removeOption = (option): void => {
    handleRemoveClick(option);
  };

  const deleteOption = (option): void => {
    handleDeleteClick(option);
  };

  const handleAddNewOption = (): void => {
    handleAddClick(newTypedOption);
    setAddOptionSelected(false);
  };

  const listOptions = (): ReactElement[] => {
    return localOptions.map((option) => {
      return (
        <ListOption
          key={option}
          onClick={(e): void => {
            e.stopPropagation();
            handleOptionClick(option);
          }}
          selected={selected.find((obj) => obj === option)}
        >
          {addOption ? (
            <OptionWrapper
              onClick={(e): void => {
                e.stopPropagation();
                handleOptionClick(option);
              }}
              selected={selected.find((obj) => obj === option)}
            >
              {option}
              <DeleteButton
                onClick={(e): void => {
                  e.stopPropagation();
                  deleteOption(option);
                }}
              >
                <img src={selected.find((obj) => obj === option) ? bin : redBin} alt="bin" />
              </DeleteButton>
            </OptionWrapper>
          ) : (
            option
          )}
        </ListOption>
      );
    });
  };

  const addOptionSection = (): ReactElement => {
    return (
      <FlexWrapper>
        {addOptionSelected ? (
          <InputButtonWrapper onClick={(e): void => e.stopPropagation()}>
            <StyledInput
              onChange={(e): void => {
                e.stopPropagation();
                setNewTypedOption(e.target.value);
              }}
            />
            <ConfirmButton
              onClick={(e): void => {
                e.stopPropagation();
                handleAddNewOption();
              }}
            >
              <img src={tickIcon} alt="tick" />
            </ConfirmButton>
          </InputButtonWrapper>
        ) : (
          <AddButton
            onClick={(e): void => {
              e.stopPropagation();
              setAddOptionSelected(true);
            }}
          >
            Add new option
          </AddButton>
        )}
      </FlexWrapper>
    );
  };

  const selectedOptions = (): ReactElement[] => {
    if (!selected || typeof selected === 'string') {
      return [];
    }
    return selected?.map((option) => {
      return (
        <SelectedOption key={option}>
          {option}
          <CloseButton
            onClick={(e): void => {
              e.stopPropagation();
              removeOption(option);
            }}
          >
            <img src={closeIcon} alt="close" />
          </CloseButton>
        </SelectedOption>
      );
    });
  };

  useEffect(() => {
    if (options) {
      setLocalOptions(options);
    }
  }, [options]);

  return (
    <Wrapper>
      <StyledButton showOptions={showOptions} ref={buttonRef} onClick={(): void => handleInputClick()}>
        {selectedOptions()}
      </StyledButton>
      {showOptions ? (
        <Portal>
          <InvisibleBackground
            style={backgroundStyling}
            onClick={(e): void => {
              e.stopPropagation();
              setShowOptions(false);
            }}
          >
            <OptionsWrapper style={optionsStyling}>
              {listOptions()}
              {addOption ? addOptionSection() : null}
            </OptionsWrapper>
          </InvisibleBackground>
        </Portal>
      ) : null}
    </Wrapper>
  );
};

export default TFSelectDropdown;
