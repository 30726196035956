import { BookItem, BookRectification } from '../deferrableDefectBook';
import { BaseModel } from '..';

const initialState: DefectsState = {
  defectsMap: new Map(),
  pagination: {},
  lastFetched: 0,
  drawerDefect: null,
  params: {},
  filters: {},
  ephemeralDefect: {},
  publicDefect: {
    lastFetched: 0,
  },
  crsClicked: false,
};

export enum DefectsActionTypes {
  RESET_EPHEMERAL_DEFECT = 'defects/resetEphemeralDefect',
  SAVE_ALL = 'defects/saveAll',
  SAVE_FILTERS = 'defects/saveFilters',
  SAVE_PARAMS = 'defects/saveParams',
  SAVE_PUBLIC_DEFECT = 'defects/savePublicDefect',
  SAVE = 'defects/save',
  SAVE_DEFECTS_DRAWER = 'defects/saveDefectsDrawer',
  UPDATE_EPHEMERAL_DEFECT = 'defects/updateEphemeralDefect',
  UPDATE_DRAWER_DEFECT = 'defects/updateDrawerDefect',
  REMOVE = 'defects/remove',
  HANDLE_CRS_CLICKED = 'defects/handleCrsClicked',
}

export interface ResetEphemeralDefectsActionType {
  type: DefectsActionTypes.RESET_EPHEMERAL_DEFECT;
}

export interface SaveAllDefectsActionType {
  type: DefectsActionTypes.SAVE_ALL;
  payload: { list: Defect[]; pagination: {} };
}

export interface SaveFiltersEphemeralDefectsActionType {
  type: DefectsActionTypes.SAVE_FILTERS;
  payload: Defect;
}

export interface SaveParamsDefectsActionType {
  type: DefectsActionTypes.SAVE_PARAMS;
  payload: Defect;
}

export interface SavePublicDefectsActionType {
  type: DefectsActionTypes.SAVE_PUBLIC_DEFECT;
  payload: Defect;
}

export interface SaveDefectsActionType {
  type: DefectsActionTypes.SAVE;
  payload: Defect;
}

export interface SaveDefectDrawerActionType {
  type: DefectsActionTypes.SAVE_DEFECTS_DRAWER;
  payload: Defect;
}

export interface RemoveDefectsActionType {
  type: DefectsActionTypes.REMOVE;
  payload: { removeId };
}

export interface UpdateEphemeralDefectsActionType {
  type: DefectsActionTypes.UPDATE_EPHEMERAL_DEFECT;
  payload: Defect;
}

export interface UpdateDrawerDefect {
  type: DefectsActionTypes.UPDATE_DRAWER_DEFECT;
  payload: Defect;
}

export interface HandleCrsClickedActionType {
  type: DefectsActionTypes.HANDLE_CRS_CLICKED;
  payload: boolean;
}

type DefectsAction =
  | UpdateEphemeralDefectsActionType
  | SaveDefectsActionType
  | SavePublicDefectsActionType
  | SaveParamsDefectsActionType
  | SaveFiltersEphemeralDefectsActionType
  | SaveAllDefectsActionType
  | RemoveDefectsActionType
  | SaveDefectDrawerActionType
  | UpdateDrawerDefect
  | HandleCrsClickedActionType
  | ResetEphemeralDefectsActionType;

export default function (state = initialState, action: DefectsAction): DefectsState {
  switch (action.type) {
    case DefectsActionTypes.SAVE_ALL: {
      const defectsMap = new Map(); // @TODO: build map from previous entries and use client side filtering
      if (action.payload && action.payload.list) {
        action.payload.list.forEach((defect) => {
          defectsMap.set(defect.id, {
            ...defectsMap.get(defect.id),
            ...defect,
          });
        });
      }
      return {
        ...state,
        defectsMap,
        lastFetched: Date.now(),
        pagination: action?.payload?.pagination || {},
      };
    }
    case DefectsActionTypes.SAVE: {
      const defectsMap = new Map(state.defectsMap.entries());
      const defect = action.payload[0];
      if (defect && defect.id) {
        defectsMap.set(defect.id, {
          ...defectsMap.get(defect.id),
          ...defect,
          lastFetched: Date.now(),
        });
      }
      return {
        ...state,
        defectsMap,
        drawerDefect: defect,
      };
    }
    case DefectsActionTypes.SAVE_DEFECTS_DRAWER: {
      const defect = action.payload[0];
      return {
        ...state,
        drawerDefect: defect,
      };
    }
    case DefectsActionTypes.UPDATE_DRAWER_DEFECT: {
      return {
        ...state,
        drawerDefect: action.payload[0],
      };
    }
    case DefectsActionTypes.REMOVE: {
      const defectsMap = new Map(state.defectsMap.entries());
      if (action.payload.removeId) {
        defectsMap.delete(action.payload.removeId);
      }
      return {
        ...state,
        defectsMap,
        lastFetched: Date.now(),
      };
    }
    case DefectsActionTypes.SAVE_PARAMS: {
      return {
        ...state,
        params: action.payload,
      };
    }
    case DefectsActionTypes.SAVE_FILTERS: {
      return {
        ...state,
        filters: action.payload,
      };
    }
    case DefectsActionTypes.UPDATE_EPHEMERAL_DEFECT: {
      return {
        ...state,
        ephemeralDefect: {
          ...state.ephemeralDefect,
          ...action.payload,
        },
      };
    }
    case DefectsActionTypes.RESET_EPHEMERAL_DEFECT: {
      return {
        ...state,
        ephemeralDefect: {},
      };
    }
    case DefectsActionTypes.SAVE_PUBLIC_DEFECT: {
      return {
        ...state,
        publicDefect: {
          ...action.payload,
          lastFetched: Date.now(),
        },
      };
    }
    case DefectsActionTypes.HANDLE_CRS_CLICKED: {
      return {
        ...state,
        crsClicked: action.payload,
      };
    }
    default:
      return state;
  }
}

export interface DefectsState {
  defectsMap: Map<string, Defect>;
  drawerDefect: Defect;
  pagination: {};
  lastFetched: number;
  params: {};
  filters: {};
  ephemeralDefect: {};
  publicDefect: {
    lastFetched: number;
  };
  crsClicked: boolean;
}

export interface RectificationIntervalExtension {
  id: string;
  extension_date: string;
  extension_date_due: string;
}

export interface Defect extends BaseModel {
  ata_chapter: number;
  ata_section: number;
  raised_at: string;
  id: string;
  flight_id: string;
  last_updated: number;
  number: number;
  date: string;
  date_due: string;
  datetime_due?: string;
  date_closed: string;
  days_remaining: number;
  limitations: string;
  deferred: boolean;
  deferralApplied?: boolean;
  defer_approval_reference: string;
  deferred_at?: string;
  deferred_by_id?: string;
  deferral_signature_url?: string;
  deferral_signature_image_url?: string;
  deferral_signature_data?: string;
  deferral_licence_number?: string;
  deferral_approval_number?: string;
  defect_type: DefectType;
  details: string;
  display_data: {
    ata: string;
    ata_section: number;
    category: string;
    type: string;
  };
  erops_prevented?: boolean;
  reference?: string;
  flight_seconds_limit: number;
  seconds_limit: number;
  flight_days_limit: number;
  cycles_limit: number;
  flights_limit: number;
  calendar_days_limit: number;
  apu_seconds_limit: number;
  other_limit: string;
  book_item?: BookItem;
  book_item_id?: string;
  rectification_id?: string;
  rectification?: BookRectification;
  mx_events?: Array<DefectMXEvents>;
  status: string;
  ddl_url: string;
  creation_srp_url: string;
  resolution_srp_url: string;
  reason_to_defer: string;
  reported_by_type: string;
  trip: {
    number: string;
  };
  flight: {
    sector: string;
  };
  aircraft: {
    registration: string;
    serial_number: string;
    id: string;
    apu_installed: boolean;
  };
  attachments: DefectAttachment[];
  signature_url: string;
  signature_image_url: string;
  signature_data: string;
  approval_signature_url: string;
  approval_signature_image_url: string;
  approval_signature_data?: string;
  versions: [];
  reporter: {
    aircraft: any[];
    avatar_url: string;
    email: string;
    first_name: string;
    has_login: boolean;
    id: string;
    last_name: string;
    last_updated: number;
    position: string;
    role: string;
    type: string;
    organisation: {
      address: string;
      aoc_number: string;
      count_operator_registries: number;
      custom_signoff_text: unknown;
      dark_logo_url: string;
      date_format: string;
      ddl_template: unknown;
      id: string;
      logo_url: string;
      name: string;
      registries: unknown;
    };
  };
  edited_by?: {
    aircraft: any[];
    avatar_url: string;
    email: string;
    first_name: string;
    has_login: boolean;
    id: string;
    last_name: string;
    last_updated: number;
    position: string;
    role: string;
    type: string;
    organisation: {
      address: string;
      aoc_number: string;
      count_operator_registries: number;
      custom_signoff_text: unknown;
      dark_logo_url: string;
      date_format: string;
      ddl_template: unknown;
      id: string;
      logo_url: string;
      name: string;
      registries: unknown;
    };
  };
  deferred_by?: {
    aircraft: any[];
    avatar_url: string;
    email: string;
    first_name: string;
    has_login: boolean;
    id: string;
    last_name: string;
    last_updated: number;
    position: string;
    role: string;
    type: string;
    organisation: {
      address: string;
      aoc_number: string;
      count_operator_registries: number;
      custom_signoff_text: unknown;
      dark_logo_url: string;
      date_format: string;
      ddl_template: unknown;
      id: string;
      logo_url: string;
      name: string;
      registries: unknown;
    };
  };
  edited_date?: string;
  rectification_interval_extension: RectificationIntervalExtension;
  troubleshooting_steps?: string;
  publicly_deferred_by_first_name?: string;
  publicly_deferred_by_last_name?: string;
  stage_of_flight?: string;
  third_party_references?: { [key: string]: unknown };
  show_approve_deferral?: boolean;
  show_approve_resolution?: boolean;
}

export interface DefectAttachment {
  attachment: any;
  id: string;
  file_name: string;
  include_in_document_store: boolean;
  category: string;
  valid_from: string;
  valid_to: string;
  organisation_id: string;
  status: string;
  url: string;
  pdf_url: string;
  thumbnail_url: string;
  content_type: string;
  attachment_file_name?: string;
  _destroy?: boolean;
}

export interface DefectMXEvents {
  approval_number: string;
  attachments: any[];
  company_email: string;
  company_name: string;
  date: string;
  description: string;
  first_name: string;
  id: string;
  last_name: string;
  licence_number: string;
  limitations: string;
  mx_item_resolved: boolean;
  part_changes: any[];
  public_signature_image_url: string;
  release_id?: string;
  reporter_first_name: string;
  reporter_last_name: string;
  signature_image_url: string;
  signature_url: string;
  work_type: string;
  workpack_reference: string;
}

export enum DefectType {
  CDL = 'CDL',
  MEL = 'MEL',
  NEF = 'NEF',
  CAS = 'CAS',
  Other = 'Other',
}

export enum BookItemType {
  MEL = 'MelItem',
  CDL = 'CdlItem',
  NEF = 'NefItem',
}

export enum RectificationType {
  MEL = 'MelRectification',
  CDL = 'CdlRectification',
  NEF = 'NefRectification',
}

export enum DefectCategory {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  ADVISORY_ONLY = 'Advisory Only',
}

export const ResolutionTypes = [
  { title: 'Inspected', value: 'inspected' },
  { title: 'Part Replaced', value: 'replaced' },
  { title: 'Other', value: 'other' },
];

export enum DefectPartsReplacedFields {
  PART_NUMBER_OFF = 'part_number',
  PART_NUMBER_ON = 'part_number_on',
  SERIAL_OFF = 'serial_off',
  SERIAL_ON = 'serial_on',
  BATCH_OFF = 'batch_off',
  BATCH_LOT_NUMBER = 'batch',
  POSITION = 'position',
  PART_DESCRIPTION = 'part_description',
}
