import axios, { AxiosPromise, AxiosResponse } from 'axios';
import { message } from 'antd';
import Cookies from 'universal-cookie';
import { stringify } from 'qs';
import moment, { Moment } from 'moment';
import { User } from '../models/userSettings';
import { Aircraft } from '../models/aircraft';
import { Airport } from '../models/airports';
import { FlightRoute, OperationsReportCoordinate } from '../models/operations';
import config, { ConfigKey } from '../utils/config';
import { Maintenance } from '../models/maintenance';
import { getOauthUrl } from '../utils/oauth';
import {
  CabinIssueSubLocation,
  FetchAircraftSublocationGroupListsPayload,
  FetchAircraftSublocationPayload,
  UpdateAircraftSublocationGroupListParams,
} from '../models/cabinIssues';
import { Defect } from '../models/defects';

const cookies = new Cookies();

const getDefaultHeaders = (): {} => ({
  Authorization: `Bearer ${cookies.get('auth')}`,
  Accept: 'application/json',
  'Content-Type': 'application/json',
});

export const approveDefectProcess = (defectId: string, processType: string): Promise<AxiosResponse<Defect>> => {
  return axios({
    method: 'POST',
    url: `${config.get(ConfigKey.API)}/defects/${defectId}/approve`,
    data: { process_type: processType },
    headers: getDefaultHeaders(),
  });
};

export const approveMxEventProcess = (mxEventId: string, processType: string): Promise<AxiosResponse<Defect>> => {
  return axios({
    method: 'POST',
    url: `${config.get(ConfigKey.API)}/mx_events/${mxEventId}/approve`,
    data: { process_type: processType },
    headers: getDefaultHeaders(),
  });
};

export const getAircraftSublocationGroupLists = (
  payload: FetchAircraftSublocationGroupListsPayload,
): Promise<AxiosResponse<CabinIssueSubLocation[]>> => {
  return axios({
    method: 'GET',
    url: `${config.get(ConfigKey.API)}/aircraft/${payload.aircraftId}/aircraft_sub_location_group_lists`,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
      Accept: 'application/json',
      'Api-Version': 5,
      'Content-Type': 'application/json',
    },
  });
};

export const getAircraftSublocationGroupList = (
  payload: FetchAircraftSublocationPayload,
): Promise<AxiosResponse<CabinIssueSubLocation>> => {
  return axios({
    method: 'GET',
    url: `${config.get(ConfigKey.API)}/aircraft/${payload.aircraftId}/aircraft_sub_location_group_lists/${payload.id}`,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
      Accept: 'application/json',
      'Api-Version': 5,
      'Content-Type': 'application/json',
    },
  });
};

export const updateAircraftSublocationGroupList = (
  params: UpdateAircraftSublocationGroupListParams,
): Promise<AxiosResponse<CabinIssueSubLocation>> => {
  return axios({
    method: 'PUT',
    url: `${config.get(ConfigKey.API)}/aircraft/${params.aircraftId}/aircraft_sub_location_group_lists/${params.id}`,
    data: params,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
      Accept: 'application/json',
      'Api-Version': 5,
      'Content-Type': 'application/json',
    },
  });
};

export const getMxItems = (params): Promise<any> => {
  return axios({
    method: 'GET',
    url: `${config.get(ConfigKey.API)}/scheduled_mx_items`,
    params,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
      Accept: 'application/json',
      'Api-Version': 5,
      'Content-Type': 'application/json',
    },
  });
};

export const getSingleMxItem = (params): Promise<any> => {
  return axios({
    method: 'GET',
    url: `${config.get(ConfigKey.API)}/scheduled_mx_items/${params.id}`,
    params,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
      Accept: 'application/json',
      'Api-Version': 5,
      'Content-Type': 'application/json',
    },
  });
};

export const getMxItemsTotals = (params): Promise<any> => {
  return axios({
    method: 'GET',
    url: `${config.get(ConfigKey.API)}/scheduled_mx_items/totals`,
    params,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
      Accept: 'application/json',
      'Api-Version': 5,
      'Content-Type': 'application/json',
    },
  });
};

const parseMonthlySummary = (body: MonthlySummary[], dateFormat: string): ParsedMonthlySummary[] => {
  const monthly = [];

  for (const entry of body) {
    const period = moment(`${entry.year}-${entry.month}`, 'YYYY-MM');
    const parsedEntry: ParsedMonthlySummary = {
      period,
      periodFormatted: moment(period).format(dateFormat),
      totalFlights: entry.total_flights === null ? 0 : entry.total_flights,
      totalFuel: entry.total_fuel_used_kg === null ? 0 : Number(Number(entry.total_fuel_used_kg).toFixed(0)),
      totalFuelUplifted:
        entry.total_fuel_uplifted_kg === null ? 0 : Number(Number(entry.total_fuel_uplifted_kg).toFixed(0)),
      fuelHour: entry.average_fuel_per_hour_kg === null ? 0 : Number(Number(entry.average_fuel_per_hour_kg).toFixed(1)),
      totalHours: entry.total_duration === null ? 0 : Number((entry.total_duration / 3600).toFixed(0)),
      cycleHour: entry.average_hours_per_landing === null ? 0 : Number(entry.average_hours_per_landing.toFixed(2)),
      fuelDeficit: null,
    };
    const i = body.indexOf(entry);
    parsedEntry.fuelDeficit =
      i === 0
        ? parsedEntry.totalFuel - parsedEntry.totalFuelUplifted
        : parsedEntry.totalFuel - parsedEntry.totalFuelUplifted + monthly[i - 1].fuelDeficit;
    monthly.push(parsedEntry);
  }

  return monthly.sort((a, b) => a.period - b.period);
};

export const getAirports = async (params: {}): Promise<Airport[]> => {
  const res = await axios({
    method: 'GET',
    url: `${config.get(ConfigKey.API)}/airports/search?${stringify({ search: params })}&limit=20`,
    responseType: 'json',
    headers: getDefaultHeaders(),
  });
  return res.data;
};

export const getMe = (): AxiosPromise<User> =>
  axios({
    url: `${config.get(ConfigKey.API)}/me`,
    method: 'get',
    responseType: 'json',
    headers: getDefaultHeaders(),
  });

export const getTopAircraft = async (
  params: GetFlightsParams,
): Promise<AxiosResponse<{ hours: OperationsReportCoordinate[]; flights: OperationsReportCoordinate[] }>> => {
  const res = await axios({
    method: 'get',
    url: params.aircraft_id
      ? `${config.get(ConfigKey.API)}/flights/aircraft?from_date=${params.from}&to_date=${params.to}&aircraft_id[]=${
          params.aircraft_id
        }`
      : `${config.get(ConfigKey.API)}/flights/aircraft?from_date=${params.from}&to_date=${params.to}`,
    responseType: 'json',
    headers: getDefaultHeaders(),
  });
  const [hours, flights] = res.data.reduce(
    ([hoursAcc, flightsAcc], entry) => {
      hoursAcc.push({
        x: entry.registration,
        y: entry.total_duration / 3600,
      });
      flightsAcc.push({
        x: entry.registration,
        y: entry.flights_count,
      });
      return [hoursAcc, flightsAcc];
    },
    [[], []],
  );

  const data = {
    hours: hours.sort((a, b) => parseFloat(b.y) - parseFloat(a.y)),
    flights: flights.sort((a, b) => parseFloat(b.y) - parseFloat(a.y)),
  };

  return {
    ...res,
    data,
  };
};

export const queryAircraftByOperator = async (operatorId): Promise<AxiosResponse<[]>> => {
  try {
    const res = await axios({
      method: 'get',
      url: `${config.get(ConfigKey.API)}/operators/${operatorId}/aircraft`,
      responseType: 'json',
      headers: getDefaultHeaders(),
    });
    return {
      ...res,
    };
  } catch (error) {
    if (error.response && error.response.status === 401) {
      window.location.href = getOauthUrl(window.location.pathname);
    }
    throw error;
  }
};

export const querySimpleAircraftByOperator = async (operatorId): Promise<AxiosResponse<[]>> => {
  try {
    const res = await axios({
      method: 'get',
      url: `${config.get(ConfigKey.API)}/operators/${operatorId}/simple_aircraft`,
      responseType: 'json',
      headers: getDefaultHeaders(),
    });
    return {
      ...res,
    };
  } catch (error) {
    if (error.response && error.response.status === 401) {
      window.location.href = getOauthUrl(window.location.pathname);
    }
    throw error;
  }
};

export const queryFavouriteAircraft = async (): Promise<AxiosResponse<[]>> => {
  try {
    const res = await axios({
      method: 'get',
      url: `${config.get(ConfigKey.API)}/aircraft/favourites`,
      responseType: 'json',
      headers: getDefaultHeaders(),
    });
    return res;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      window.location.href = getOauthUrl(window.location.pathname);
    }
    throw error;
  }
};

export const queryOperatorRegistrations = async (): Promise<AxiosResponse<[]>> => {
  const res = await axios({
    method: 'get',
    url: `${config.get(ConfigKey.API)}/operators/operator_registrations`,
    responseType: 'json',
    headers: getDefaultHeaders(),
  });

  return res;
};

export const getTopAirports = async (
  params: GetFlightsParams,
): Promise<AxiosResponse<OperationsReportCoordinate[]>> => {
  const res = await axios({
    method: 'get',
    url: params.aircraft_id
      ? `${config.get(ConfigKey.API)}/flights/airports?from_date=${params.from}&to_date=${params.to}&aircraft_id[]=${
          params.aircraft_id
        }`
      : `${config.get(ConfigKey.API)}/flights/airports?from_date=${params.from}&to_date=${params.to}`,
    responseType: 'json',
    headers: getDefaultHeaders(),
  });

  const data = res.data
    .map(({ ident, count }) => ({
      x: ident,
      y: count,
    }))
    .sort((a, b) => parseFloat(b.y) - parseFloat(a.y));

  return {
    ...res,
    data,
  };
};

export const getMonthlySummary = async (
  params: MonthlySummaryParams,
): Promise<AxiosResponse<ParsedMonthlySummary[]>> => {
  const aircraftParams = [];
  params.aircraft.forEach((item) => {
    const newItem = `aircraft_id[]=${item}`;
    aircraftParams.concat(newItem);
  });
  const res = await axios({
    method: 'get',
    url: `${config.get(ConfigKey.API)}/operators/${params.organisation_id}/monthly_summary?${aircraftParams}`,
    params: {
      aircraft_ids: params.aircraft,
    },
    paramsSerializer: (queryParams) => stringify(queryParams, { arrayFormat: 'brackets', encode: false }),
    responseType: 'json',
    headers: getDefaultHeaders(),
  });

  const data = parseMonthlySummary(res.data, params.dateFormat);

  return {
    ...res,
    data,
  };
};

export const getOilConsumption = async (params: GetOilConsumptionParams): Promise<AxiosResponse<OilConsumption>> => {
  const res = await axios({
    method: 'get',
    url: `${config.get(ConfigKey.API)}/aircraft/${params.aircraft}/oil_consumption?range=${params.range}`,
    responseType: 'json',
    headers: getDefaultHeaders(),
  });
  const newObject = res.data;
  const dataArray = newObject.data;
  const parsedData = [];
  for (const entry of dataArray) {
    const newDate = moment(entry.date, 'YYYY-MM-DD');
    const formattedDate = moment(newDate).format(params.dateFormat);
    const dataObject = { ...entry, date: formattedDate };
    parsedData.push(dataObject);
  }
  const amendedObject = { ...newObject, data: parsedData };
  return amendedObject;
};

export const getRoutes = async (params: GetRoutesParams): Promise<AxiosResponse<FlightRoute[]>> => {
  const res: any = await axios({
    method: 'get',
    url: `${config.get(ConfigKey.API)}/flights/routes?${params.from ? `from_date=${params.from}&` : ''}to_date=${
      params.to
    }`,
    params: {
      aircraft_ids: params.aircraft,
    },
    paramsSerializer: (queryParams) => stringify(queryParams, { arrayFormat: 'brackets', encode: false }),
    responseType: 'json',
    headers: getDefaultHeaders(),
  }).catch((e) => console.error(e));

  const data = res.data.map(({ arrival, departure }) => ({
    departure,
    arrival,
    startLat: parseFloat(departure.latitude),
    startLng: parseFloat(departure.longitude),
    endLat: parseFloat(arrival.latitude),
    endLng: parseFloat(arrival.longitude),
  }));

  return {
    ...res,
    data,
  };
};

export const newGetAircraftDefects = async (params: any): Promise<AxiosResponse<any>> => {
  const res: any = await axios({
    method: 'get',
    url: `${config.get(ConfigKey.API)}/aircraft/${params.aircraft_id}/defects/all`,
    params,
    paramsSerializer: (queryParams) => stringify(queryParams, { arrayFormat: 'brackets', encode: false }),
    responseType: 'json',
    headers: getDefaultHeaders(),
  }).catch((e) => console.error(e));

  return res;
};

export const newGetAllDefects = async (params: any): Promise<AxiosResponse<any>> => {
  const res: any = await axios({
    method: 'get',
    url: `${config.get(ConfigKey.API)}/defects/all`,
    params,
    paramsSerializer: (queryParams) => stringify(queryParams, { arrayFormat: 'brackets', encode: false }),
    responseType: 'json',
    headers: getDefaultHeaders(),
  }).catch((e) => console.error(e));

  return res;
};

export const getDefectTotals = async (params: any): Promise<AxiosResponse<any>> => {
  const res: any = await axios({
    method: 'get',
    url: `${config.get(ConfigKey.API)}/defects/totals`,
    params,
    paramsSerializer: (queryParams) => stringify(queryParams, { arrayFormat: 'brackets', encode: false }),
    responseType: 'json',
    headers: getDefaultHeaders(),
  }).catch((e) => console.error(e));

  return res;
};

export const queryAircraft = (params: string): AxiosPromise<Aircraft> => {
  if (!params) {
    // @NOTE once we've gone full typescript this check can be removed.
    throw new Error('Couldn not determine aircraft ID');
  }
  return axios({
    method: 'GET',
    url: `${config.get(ConfigKey.API)}/aircraft/${params}`,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Api-Version': 3,
    },
  });
};

export const fetchAircraftCardHoverData = (aircraftId: string): AxiosPromise<Aircraft> => {
  if (!aircraftId) {
    // @NOTE once we've gone full typescript this check can be removed.
    throw new Error('Could not determine aircraft ID');
  }
  return axios({
    method: 'GET',
    url: `${config.get(ConfigKey.API)}/aircraft/${aircraftId}/hover_data`,
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Api-Version': 3,
    },
  });
};

export const queryAircrafts = (): AxiosPromise<Aircraft[]> =>
  axios({
    url: `${config.get(ConfigKey.API)}/aircraft`,
    method: 'get',
    responseType: 'json',
    headers: {
      Authorization: `Bearer ${cookies.get('auth')}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Api-Version': 3,
    },
  });

export const updateAircraft = (params): AxiosPromise<Aircraft> =>
  axios({
    url: `${config.get(ConfigKey.API)}/aircraft/${params.aircraftId}`,
    method: 'put',
    responseType: 'json',
    headers: getDefaultHeaders(),
    data: params.aircraft,
  });

export const queryMaintenance = (params): AxiosPromise<Aircraft> => {
  return axios({
    url: `${config.get(ConfigKey.API)}/scheduled_mx_items?${params.join('&')}`,
    method: 'get',
    responseType: 'json',
    headers: getDefaultHeaders(),
  });
};

export const addMaintenance = (params): AxiosPromise<Maintenance> =>
  axios({
    url: `${config.get(ConfigKey.API)}/aircraft/${params.aircraft}/scheduled_mx_items`,
    method: 'post',
    responseType: 'json',
    headers: getDefaultHeaders(),
    data: params.itemData,
  });

export const updateMaintenance = (params): AxiosPromise<Maintenance> =>
  axios({
    url: `${config.get(ConfigKey.API)}/scheduled_mx_items/${params.id}`,
    method: 'put',
    responseType: 'json',
    headers: getDefaultHeaders(),
    data: params.itemData,
  });
interface GetFlightsParams {
  from: string;
  to: string;
  aircraft_id: string[];
}

export const createAircraftLogin = (params): Promise<any> => {
  return fetch(`${config.get(ConfigKey.API)}/aircraft/create_login`, {
    method: `POST`,
    headers: getDefaultHeaders(),
    body: JSON.stringify(params),
  });
};

export const addCrew = async (params): Promise<AxiosResponse<Aircraft>> => {
  try {
    const res = await axios({
      url: `${config.get(ConfigKey.API)}/aircraft/${params.aircraft}/add_person`,
      method: 'post',
      responseType: 'json',
      headers: getDefaultHeaders(),
      data: { person_id: params.person_id },
    });
    return res;
  } catch (err) {
    message.error('Failed to add / remove crew from this aircraft');
    return err;
  }
};

export const removeCrew = (params): AxiosPromise<Aircraft> =>
  axios({
    url: `${config.get(ConfigKey.API)}/aircraft/${params.aircraft}/remove_person`,
    method: 'post',
    responseType: 'json',
    headers: getDefaultHeaders(),
    data: { person_id: params.person_id },
  });

export const syncWithCamp = (params): AxiosPromise<Aircraft> =>
  axios({
    url: `${config.get(ConfigKey.API)}/aircraft/${params}/sync_camp`,
    method: 'get',
    responseType: 'json',
    headers: getDefaultHeaders(),
  });

export const queryWorkpackTotals = (id: string): AxiosPromise<Aircraft> => {
  try {
    return axios({
      method: 'GET',
      url: `${config.get(ConfigKey.API)}/workpacks/totals?aircraft_id=${id}`,
      headers: {
        Authorization: `Bearer ${cookies.get('auth')}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  } catch (err) {
    message.error('Failed to get workpack totals');
    return err;
  }
};

export const fetchSrpTemplates = (org: string): AxiosPromise<any> => {
  try {
    return axios({
      method: 'GET',
      url: `${config.get(ConfigKey.API)}/pdf_templates?operator_id=${org}`,
      headers: {
        Authorization: `Bearer ${cookies.get('auth')}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  } catch (err) {
    message.error(`Failed to get srp templates for ${org}`);
    return err;
  }
};

export const getTemplateEditingUrl = (templateId: string, orgId): AxiosPromise<any> => {
  try {
    return axios({
      method: 'GET',
      url: `${config.get(ConfigKey.API)}/pdf_templates/${templateId}/open_editor?operator_id=${orgId}`,
      headers: {
        Authorization: `Bearer ${cookies.get('auth')}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  } catch (err) {
    message.error(`Failed to generate pdf url for template: ${templateId} for org: ${orgId}`);
    return err;
  }
};

export const updateTemplateCounts = (templateId: string, orgId): AxiosPromise<any> => {
  try {
    return axios({
      method: 'PUT',
      url: `${config.get(ConfigKey.API)}/operators/${orgId}/srp_settings/update_template_counts/${templateId}`,
      headers: {
        Authorization: `Bearer ${cookies.get('auth')}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  } catch (err) {
    message.error(`Failed update template counts for ${templateId}`);
    return err;
  }
};

export const duplicateTemplate = (templateId: string, orgId): AxiosPromise<any> => {
  try {
    return axios({
      method: 'POST',
      url: `${config.get(ConfigKey.API)}/pdf_templates/${templateId}/copy_template?operator_id=${orgId}`,
      headers: {
        Authorization: `Bearer ${cookies.get('auth')}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  } catch (err) {
    message.error(`Failed to copy srp template ${templateId} for org: ${orgId}`);
    return err;
  }
};

export const createTemplate = (orgId: string, type: string): AxiosPromise<any> => {
  try {
    return axios({
      method: 'POST',
      url: `${config.get(
        ConfigKey.API,
      )}/pdf_templates?operator_id=${orgId}&template_type=${type}&template_name=New Template ${moment().format(
        'DD-MM-YY HH:mm',
      )}`,
      headers: {
        Authorization: `Bearer ${cookies.get('auth')}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  } catch (err) {
    message.error(`Failed to create new srp template for org: ${orgId}`);
    return err;
  }
};

export const updateDefaultTemplate = (templateId: string, orgId: string, type: string): AxiosPromise<any> => {
  try {
    let operator: {};
    if (type === 'srp') {
      operator = {
        srp_template_id: templateId,
      };
    }
    if (type === 'jl') {
      operator = {
        jl_template_id: templateId,
      };
    }
    return axios({
      method: 'PUT',
      url: `${config.get(ConfigKey.API)}/operators/${orgId}`,
      headers: {
        Authorization: `Bearer ${cookies.get('auth')}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      responseType: 'json',
      data: {
        operator,
      },
    });
  } catch (err) {
    message.error(`Failed to update srp template for org: ${orgId}`);
    return err;
  }
};

export const deleteTemplate = (templateId: string, orgId): AxiosPromise<any> => {
  try {
    return axios({
      method: 'DELETE',
      url: `${config.get(ConfigKey.API)}/pdf_templates/${templateId}?operator_id=${orgId}`,
      headers: {
        Authorization: `Bearer ${cookies.get('auth')}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  } catch (err) {
    message.error(`Failed to delete srp template ${templateId} for org: ${orgId}`);
    return err;
  }
};

export const getAircraftStatus = (id: string): AxiosPromise<any> => {
  try {
    return axios({
      method: 'GET',
      url: `${config.get(ConfigKey.API)}/aircraft/${id}/aircraft_status`,
      headers: {
        Authorization: `Bearer ${cookies.get('auth')}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  } catch (err) {
    message.error('Failed to get widget data');
    return err;
  }
};

export const getAircraftPeople = (id: string): AxiosPromise<any> => {
  try {
    return axios({
      method: 'GET',
      url: `${config.get(ConfigKey.API)}/aircraft/${id}/aircraft_people`,
      headers: {
        Authorization: `Bearer ${cookies.get('auth')}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  } catch (err) {
    message.error('Failed to get people');
    return err;
  }
};

export const getAircraftTOTP = (aircraftId: string): AxiosPromise<{ otp: string }> => {
  try {
    return axios({
      method: 'GET',
      url: `${config.get(ConfigKey.API)}/aircraft/${aircraftId}/totp`,
      headers: getDefaultHeaders(),
    });
  } catch (error) {
    message.error('Failed to fetch One-Time Password for aircraft');
    return error;
  }
};

export interface GetOilConsumptionParams {
  organisation_id: string;
  aircraft: string[];
  range: number;
  dateFormat: string;
}

export interface OilConsumption {
  oil_unit: string;
  engine_oil_consumption_threshold: number;
  apu_oil_consumption_threshold: number;
  data: Array<OilData>;
}

interface OilData {
  date: string;
  engine_1: number;
  engine_2: unknown;
  engine_3: unknown;
  engine_4: unknown;
  apu: unknown;
}

export interface GetRoutesParams {
  from: string;
  to: string;
  aircraft: string[];
}

interface MonthlySummary {
  month: number;
  year: number;
  total_duration: number;
  total_flights: number;
  average_fuel_per_hour: string;
  total_fuel_uplifted_kg: string;
  total_fuel_used_kg: string;
  average_fuel_per_hour_kg: string;
  average_hours_per_landing: number;
}

interface ParsedMonthlySummary {
  period: Moment;
  periodFormatted: string;
  totalFlights: number;
  totalFuel: number;
  totalFuelUplifted: number;
  fuelHour: number;
  totalHours: number;
  cycleHour: number;
  fuelDeficit: number;
}

interface MonthlySummaryParams {
  organisation_id: string;
  aircraft: string[];
  dateFormat: string;
}
